import React from 'react'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'


class Home extends React.Component {
	render() {
		const { site, wpPage, allWpPortfolio, wp } = this.props.data		 
		console.log(this.props)
		return (
			<div>
				<Helmet>
					<title>{site.title}</title>
					<meta name="description" content="Aride" />
				</Helmet>
				<Layout
					isHome={true}
					sections={['home', 'about', 'services', 'portfolio', 'testimonials', 'team', 'clients', 'contact']}
				>
				<h1>{wpPage.title}</h1>
				<p>{wpPage.content}</p>
				<p>{wpPage.inicio.titulohero}</p>
				
				</Layout>
			</div>
		)
	}
}

export default Home

export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
	}
	wp {
        opcionesGenerales {
          settings {
            direccion
            email
            telefono
            telefonoVisual
          }
        }
      }
    wpPage(title: {}, slug: {eq: "home"}) {
      id
      title
      inicio {
		titulohero
	  }
	}	
	allWpPortfolio {
		nodes {
		  id
		  slug
		  title
		}
	}
  }
`
